import { useIntl } from 'react-intl'

export const useConfirmLanguageSwitch = (isDataFilled: boolean) => {
  const { formatMessage } = useIntl()

  return () => {
    const message = formatMessage({
      defaultMessage:
        'Are you sure you want to switch language? If you do so, your data will be reset to factory settings and you will have to continue again.',
      description: 'Alert message for switch language',
    })

    const condition = isDataFilled ? confirm : () => true
    return condition(message)
  }
}
