import clsx from 'classnames'
import * as React from 'react'
import ScreenLoader from '@components/ScreenLoader'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import Container from './Container'
import styles from './Content.module.scss'

const Content: React.FC<React.PropsWithChildren> = ({ children }) => {
  const languageInitialized = useBookingFormStore((state) => state.languageInitialized)
  const step = useBookingFormStore((state) => state.stepper.step)

  return (
    <main
      className={clsx(styles.root, {
        [styles.rootBackgroundGradient]: step > 1 && step < 5,
      })}
    >
      <ScreenLoader isLoading={!languageInitialized}>
        <Container>{children}</Container>
      </ScreenLoader>
    </main>
  )
}

export default Content
