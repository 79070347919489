import { updateLocaleStorageValue } from '@utils/localStorage'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { ILanguage } from '../store/data'
import { LANG_PARAM, useQueryParams } from './useQueryParams'

export const useAppLanguage = () => {
  const languageInitialized = useBookingFormStore((state) => state.languageInitialized)
  const changeLanguage = useBookingFormStore((state) => state.changeLanguage)

  const { addQueryParameter } = useQueryParams()

  const handleChangeAppLanguage = (language: ILanguage) => {
    changeLanguage(language)
    updateLocaleStorageValue('lang', language)
    addQueryParameter(LANG_PARAM, language)
  }

  return {
    languageInitialized,
    onChangeAppLanguage: handleChangeAppLanguage,
  }
}
