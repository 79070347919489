'use client'

import { createContext, type ReactNode, useContext, useRef } from 'react'
import { useStore } from 'zustand'
import {
  type BookingFormStore,
  createBookingFormStore,
  initBookingFormStore,
} from '../store/bookingFormStore'

export type BookingFormStoreApi = ReturnType<typeof createBookingFormStore>

export const BookingFormStoreContext = createContext<BookingFormStoreApi | undefined>(undefined)

export interface BookingFormStoreProviderProps {
  children: ReactNode
}

/*
 * Note: In this example, we ensure that this component is re-render-safe by checking the value of the reference,
 * so that the store is only created once. This component will only be rendered once per request on the server,
 * but might be re-rendered multiple times on the client if there are stateful client components located above this
 * component in the tree, or if this component also contains other mutable state that causes a re-render.
 *
 * See more at https://zustand.docs.pmnd.rs/guides/nextjs
 */
export const BookingFormStoreProvider = ({ children }: BookingFormStoreProviderProps) => {
  const storeRef = useRef<BookingFormStoreApi>()
  if (!storeRef.current) {
    storeRef.current = createBookingFormStore(initBookingFormStore())
  }

  return (
    <BookingFormStoreContext.Provider value={storeRef.current}>
      {children}
    </BookingFormStoreContext.Provider>
  )
}

export const useBookingFormStore = <T,>(selector: (store: BookingFormStore) => T): T => {
  const bookingFormStoreContext = useContext(BookingFormStoreContext)

  if (!bookingFormStoreContext) {
    throw new Error(`useBookingFormStore must be used within BookingFormStoreProvider`)
  }

  return useStore(bookingFormStoreContext, selector)
}
