import clsx from 'classnames'
import * as React from 'react'
import CheckIcon from '../CheckIcon'
import Divider from './Divider'
import styles from './StepperItem.module.scss'

type StepperItem = {
  label: number
  separator?: boolean
  step: number
}

const StepperItem: React.FunctionComponent<StepperItem> = ({ label, separator = true, step }) => (
  <div className={styles.root}>
    <div
      className={clsx(styles.wrapper, {
        [styles.wrapperCurrent]: label === step,
        [styles.wrapperGreater]: label > step,
        [styles.wrapperLess]: label < step,
      })}
    >
      {label === step ? (
        label
      ) : (
        <div className={styles.item}>{label < step ? <CheckIcon size={24} /> : label}</div>
      )}
    </div>
    {separator && <Divider />}
  </div>
)

export default StepperItem
