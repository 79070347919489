import * as React from 'react'
import { useEffect } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { getLocalStorageValue, SEGMENT_KEYS } from '@utils/localStorage'
import messagesCs from '../../compiled-lang/cs.json'
import messagesDe from '../../compiled-lang/de.json'
import messagesEn from '../../compiled-lang/en.json'
import messagesRu from '../../compiled-lang/ru.json'
import { getCustomAppInstanceLanguage, getLangIso } from '../helpers'
import { useAppLanguage } from '../hooks/useAppLanguage'
import { useQueryParams } from '../hooks/useQueryParams'
import { ILanguage, LanguageEnum } from '../store/data'
import { DEFAULT_LANGUAGE } from '../store/state'
import { useBookingFormStore } from './BookingFormStoreProvider'

const getMessages = (language: ILanguage) => {
  if (language === LanguageEnum.CS) {
    return messagesCs
  }
  if (language === LanguageEnum.DE) {
    return messagesDe
  }
  if (language === LanguageEnum.RU) {
    return messagesRu
  }

  if (language === LanguageEnum.EN) {
    return messagesEn
  }

  return messagesDe
}

const IntlProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { onChangeAppLanguage } = useAppLanguage()
  const { routerQueryParams } = useQueryParams()

  const language = useBookingFormStore((state) => state.language)

  useEffect(() => {
    const customAppInstanceLanguage = getCustomAppInstanceLanguage()

    if (!customAppInstanceLanguage) {
      const routeQueryLang = routerQueryParams.lang ? getLangIso(routerQueryParams.lang) : null
      const appLanguage = routeQueryLang || getLocalStorageValue(SEGMENT_KEYS.lang) || language
      onChangeAppLanguage(appLanguage as ILanguage)
    } else {
      onChangeAppLanguage(customAppInstanceLanguage)
    }
  }, [])

  return (
    <ReactIntlProvider
      defaultLocale={DEFAULT_LANGUAGE}
      key={language}
      locale={language}
      messages={getMessages(language)}
    >
      {children}
    </ReactIntlProvider>
  )
}

export default IntlProvider
