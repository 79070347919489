import { ReactNode } from 'react'
import styles from '@components/FooterBackgroundWrapper.module.scss'

interface FooterBackgroundWrapperProps {
  children: ReactNode
  footerWithBackground: boolean
}

export const FooterBackgroundWrapper = ({
  children,
  footerWithBackground,
}: FooterBackgroundWrapperProps) => {
  if (!footerWithBackground) {
    return <>{children}</>
  }

  return <div className={styles.rootBackgroundWrapper}>{children}</div>
}
