import clsx from 'classnames'
import * as React from 'react'
import BasicButton, { BasicButtonProps } from './BasicButton'
import styles from './Button.module.scss'

interface ButtonProps extends BasicButtonProps {
  iconPrefix?: React.ReactNode
  iconSuffix?: React.ReactNode
  variant?: 'outlined' | 'contained' | 'custom'
}

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className,
  iconPrefix,
  iconSuffix,
  variant = 'outlined',
  ...rest
}) => (
  <BasicButton
    {...rest}
    className={clsx(
      styles.root,
      {
        [styles.rootContained]: variant === 'contained',
        [styles.rootOutlined]: variant === 'outlined',
      },
      className
    )}
    clearBasicStyle
  >
    {iconPrefix && <div className={styles.rootIconPrefix}>{iconPrefix}</div>}
    <div>{children}</div>
    {iconSuffix && <div className={styles.rootIconSuffix}>{iconSuffix}</div>}
  </BasicButton>
)

export default Button
