import * as React from 'react'
import { FieldValues } from 'react-hook-form'

export type Identificator = string | number

export enum LanguageEnum {
  CS = 'cs',
  DE = 'de',
  EN = 'en',
  RU = 'ru',
}

export enum CurrencyEnum {
  CZK = 'CZK',
  EUR = 'EUR',
}

export enum AccordionEnum {
  DESTINATION,
  GUESTS,
  HOTELS,
  TYPE_OF_STAY,
  ROOMS,
  CALENDAR,
}

export enum PaymentEnum {
  Bank = 'bank',
}

export enum Loaders {
  BOOKING = 'booking',
}

export interface ILang {
  cs_CZ: string | null
  de: string | null
  en: string | null
  ru: string | null
}

export interface IHotelParamsQuery {
  date_from?: string | null
  date_to?: string | null
  destination_id?: Identificator | null
  visit_type_id?: Identificator | null
}

export interface IVisitTypeCategoryParamsQuery {
  destination_id?: Identificator | null
}

export interface IInitDataParametersQuery {
  date_from?: string | null
  date_to?: string | null
  destination_name?: string | null
  guest?: number | null
  hotel_name?: string | null
  lang?: keyof ILang | 'cs' | null
  room_type_category?: string | null
  visit_type_name?: string | null
}

export interface IRouteQueryParams {
  affilUserName?: string | null
  destination?: string | null
  guest?: string | null
  hotel?: string | null
  lang?: keyof ILang | ILanguage | null
  roomType?: string | null
  visitEnd?: string | null
  visitStart?: string | null
  visitType?: string | null
}

export type IRouteQueryParamsWithDynamicParams = Record<string, string | null | undefined> &
  IRouteQueryParams

export interface IInitDataResponse {
  availability?: 'ON_DEMAND' | 'AVAILABLE' | 'NOT_AVAILABLE'
  date_from?: string | null
  date_to?: string | null
  destination?: IDestination | null
  hotel?: IHotel | null
  lowest_price?: IPrice | null
  price?: IPrice | null
  room_type?: IRoomType | null
  visit_type?: IVisitType | null
}

export interface IInitDataSuccessPayload extends Omit<IInitDataResponse, 'date_from' | 'date_to'> {
  date_from: Date | null
  date_to: Date | null
  destination: IDestination | null
  guest: number | null
}

export type IValidationState =
  | 'DESTINATIONS'
  | 'GUESTS'
  | 'VISIT_TYPE'
  | 'HOTEL'
  | 'ROOM_TYPE'
  | null

export interface IDestination {
  id: Identificator
  name: ILang
}

export interface IVisitCategory {
  id: Identificator
  name: ILang
  visitTypes: IVisitType[]
}

export type BoardingType =
  | 'ALL_INCLUSIVE'
  | 'BREAKFAST'
  | 'DINNER'
  | 'FULL_BOARD'
  | 'HALF_BOARD'
  | 'LUNCH'

export interface IVisitType {
  boarding: BoardingType
  description: ILang
  hotels: IHotel[]
  id: Identificator
  lowest_price: IPrice
  name: ILang
  nights_min: number
  notice: ILang
  services: ILang
  tags: ITag[]
}

export interface ITag {
  id: Identificator
  name: ILang
  nights_min: number
}

export interface IRoomType {
  beds: number
  booking_url: ILang
  category: ILang
  description: ILang
  facilities: string[]
  id: Identificator
  image: string
  lowest_price: IPrice
}

export interface IPriceBreakdownDiscount {
  guest: number
  loyalty_program: ILoyaltyProgram
  price_discount: number
}

export interface IPriceBreakdown {
  base_price: number
  discounts: IPriceBreakdownDiscount[]
}

export interface IPrice {
  cs_CZ: {
    amount?: number
    amount_per_night?: number
    currency: string
    price_breakdown: IPriceBreakdown
  }
  de: {
    amount?: number
    amount_per_night?: number
    currency: string
    price_breakdown: IPriceBreakdown
  }
  en: {
    amount?: number
    amount_per_night?: number
    currency: string
    price_breakdown: IPriceBreakdown
  }
  is_final?: boolean
  ru: {
    amount?: number
    amount_per_night?: number
    currency: string
    price_breakdown: IPriceBreakdown
  }
}

export interface IHotel {
  address?: {
    address_line: string
    city: string
    country_code: string
    postal_code: string
  }
  booking_url?: ILang
  commercial_slogan?: ILang
  description?: ILang
  destination: {
    id: Identificator
    name: ILang
  }
  email?: ILang
  equipment?: ILang
  id: Identificator
  image?: string
  lowest_price?: IPrice
  name: string
  phone_number?: string
  roomTypes?: IRoomType[]
  services?: ILang
  stars?: number
}

export interface ILoyaltyProgram {
  id: Identificator
  name: ILang
}

export interface IStepper {
  step: number
  totalStep: number
}

export interface ITravelDate {
  from: Date | null
  to: Date | null
}

export interface INotification {
  closable: boolean
  isNetworkError: boolean
  message: React.ReactNode
  timestamp: number
}

export interface IImages {
  loaded: number
  loading: number
}

export type ILanguage = 'en' | 'cs' | 'de' | 'ru'
export type ICurrency = 'EUR' | 'CZK'

export interface AppState {}

export type GenderValue = 'female' | 'male' | null

export enum CalendarModeEnum {
  FROM_DATE,
  TO_DATE,
}

export enum CalendarStateEnum {
  INITIAL,
  SET,
}

export interface ICalendar {
  checkout: IDateCheckout[]
  data: IDate[]
  date: Date
  datesCache: Record<string, IDate[]>
  isDidInvalid: boolean
  isFetching: boolean
  mode: CalendarModeEnum
  price: IPrice | null
  state: CalendarStateEnum
  travelDate: ITravelDate
}

interface IDialog {
  data: any | null
  isDidInvalid: boolean
  isFetching: boolean
}

export interface HotelDialog extends IDialog {
  data: IHotel | null
}

export interface RoomTypeDialog extends IDialog {
  data: IRoomType | null
}

export interface VisitTypeDialog extends IDialog {
  data: IVisitType | null
}

export interface PersonalFormData extends FieldValues {
  birth_date: string
  city: string
  country: string
  email: string
  first_name: string
  gender: GenderValue
  house_number: string
  last_name: string
  phone: string
  street: string
  zip_code: string
}

export interface LoyaltyProgramData extends FieldValues {
  code: string
  id: Identificator
}

export type ServiceFormData = {
  loyaltyPrograms: LoyaltyProgramData[]
  note: string | null
}

export interface GuestFormData extends FieldValues {
  birth_date: string | null
  first_name: string | null
  gender: GenderValue
  isEditing: boolean
  last_name: string | null
}

export interface GuestsFormData extends FieldValues {
  guests: GuestFormData[]
}

export interface SummaryFormData extends FieldValues {
  agree: boolean
  marketing: boolean
  payment: PaymentEnum | null
}

export interface IGuest {
  birth_date: string
  first_name: string
  gender: GenderValue
  last_name: string
  loyalty_program_code?: string
  loyalty_program_id?: Identificator
}

export interface IBookingResponse {
  id: number
}

export interface IBookingRequest {
  affilUserName?: string
  billed_to: {
    address?: {
      city: string
      country: string
      house_number: string
      street: string
      zip_code: string
    }
    consent_marketing: boolean
    email: string
    first_name: string
    gender: GenderValue
    last_name: string
    phone: string
  }
  binding_booking: boolean
  currency: ICurrency
  customer_note: string | null
  date_from: string
  date_to: string
  guests: IGuest[]
  hotel_id: number
  room_type_id: number
  visit_type_id: number
}

export interface IDatesParamsQuery {
  dateMiddleCalendarDateCacheKey?: string
  dateToCalendarDateCacheKey?: string
  date_from?: string
  date_to?: string
  destination_id?: Identificator
  hotel_id?: Identificator
  initial?: boolean
  room_type_id?: Identificator
  visit_type_id?: Identificator
}

export interface IDate {
  availability: 'NOT_AVAILABLE' | 'AVAILABLE' | 'ON_DEMAND'
  date: string
  price: IPrice
  selectable: boolean
}

export interface IDateWithCache {
  dates: IDate[]
  datesCache: Record<string, IDate[]>
}

export interface IDatesCheckoutParamsQuery {
  hotel_id?: Identificator
  room_type_id?: Identificator
  start_date: string
  visit_type_id?: Identificator
}

export interface IDateCheckout {
  date: string
}

export interface ILoaders {
  [Loaders.BOOKING]: boolean
}

export interface IUnknownObject {
  [key: string]: any
}

export interface IBackEndError {
  field: string
  message: string[]
}
