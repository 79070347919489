import * as Sentry from '@sentry/nextjs'
import { IBackEndError, IUnknownObject } from '../store/data'

// use to set which errors to log in sentry
const SENTRY_CONFIG = {
  ERROR: true,
  LOG: false,
}

export const MESSAGE_LEVELS = {
  CRITICAL: 'critical',
  DEBUG: 'debug',
  ERROR: 'error',
  FATAL: 'fatal',
  INFO: 'info',
  LOG: 'log',
  WARNING: 'warning',
}

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://52972f2ed41543429c2fef4ac8e95212@o4504203360534528.ingest.sentry.io/4504282529726464',
  })

  Sentry.configureScope(function (scope) {
    // @ts-ignore known issue in sentry
    scope.setLevel(MESSAGE_LEVELS.ERROR)
  })
}

export const alertSentryOnAPIError = (error: IUnknownObject) => {
  if (!SENTRY_CONFIG.ERROR) return

  const { config, level = MESSAGE_LEVELS.ERROR, response } = error || {}
  const errorCode = String(response?.status || 422)
  const method = config?.method || 'none'
  const path = config?.url || window.location.href

  Sentry.withScope((scope) => {
    // @ts-ignore known issue in sentry
    scope.setLevel(level)
    if (config?.data) scope.setExtras(JSON.parse(config.data))

    if (response?.data?.errors) {
      response.data.errors.forEach((error: IBackEndError, i: number) => {
        Sentry.setContext(`BE_error_stack_${i}`, buildErrorStack(error))
      })
    }

    // for error grouping purposes
    scope.setFingerprint([method, path, errorCode])
    Sentry.captureException(error)
  })
}

const buildErrorStack = ({ field, message }: IBackEndError) => {
  const stack: IUnknownObject = {}

  if (field) stack['field'] = field
  if (message) stack['message'] = message.join(` // `)

  return stack
}

export const customizeError = (error: IUnknownObject, modifications: {}) =>
  Object.assign(error, modifications)

export const logToSentry = (loggedText: string, payload: IUnknownObject) => {
  if (!SENTRY_CONFIG.LOG) return

  Sentry.withScope((scope) => {
    // @ts-ignore known issue in sentry
    scope.setLevel(MESSAGE_LEVELS.LOG)
    if (payload) scope.setExtras(payload)

    Sentry.captureMessage(loggedText)
  })
}
