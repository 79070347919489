import * as React from 'react'
import { useIntl } from 'react-intl'
import BasicButton from '../BasicButton'
import CloseIcon from '../CloseIcon'
import styles from './CloseButton.module.scss'

type CloseButtonProps = {
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

const CloseButton: React.FunctionComponent<CloseButtonProps> = ({ onClose }) => {
  const { formatMessage } = useIntl()
  return (
    <BasicButton
      className={styles.root}
      clearBasicStyle
      onClick={onClose}
      title={formatMessage({
        defaultMessage: 'Close',
        description: 'Close button notification title',
      })}
    >
      <CloseIcon />
    </BasicButton>
  )
}

export default CloseButton
