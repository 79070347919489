import axios, { AxiosError } from 'axios'
import { alertSentryOnAPIError, logToSentry } from '@utils/sentry'
import { INotification } from '../store/data'

const languages = ['cs_CZ', 'de', 'ru', 'en']

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Accept: 'application/json',
    'Accept-Language': languages.join(', '),
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_TOKEN}`,
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use((config) => {
  logToSentry(`calling API end point ${config.baseURL}`, config)
  return config
})

export const handleApiError = (
  error: unknown,
  addNotification: (notification: INotification) => void
) => {
  let isNetworkError = false
  let errorMessage = 'Unknown error'

  if (error instanceof AxiosError) {
    if (error.code === 'ERR_NETWORK') {
      isNetworkError = true
    }
    errorMessage = error.message
  } else if (error instanceof Error) {
    errorMessage = error.message
  }

  addNotification({
    closable: !isNetworkError,
    isNetworkError,
    message: errorMessage,
    timestamp: new Date().getTime(),
  })

  if (error instanceof AxiosError) {
    alertSentryOnAPIError(error)
  }
}

export default instance
