import { ILanguage } from '../store/data'

interface ISegmentKeys {
  lang: 'lang'
}

const SEGMENT: 'rezervace.frantiskovylazne.cz' = 'rezervace.frantiskovylazne.cz'
export const SEGMENT_KEYS: ISegmentKeys = {
  lang: 'lang',
}

const getSegment = () => {
  try {
    return JSON.parse(localStorage.getItem(SEGMENT) as string)
  } catch {
    return localStorage.getItem(SEGMENT)
  }
}

export const updateLocaleStorageValue = (key: keyof ISegmentKeys, value: ILanguage) => {
  const localeStorageSegment = getSegment()
  const newLocaleStorageSegment = { ...localeStorageSegment, [key]: value }
  localStorage.setItem(SEGMENT, JSON.stringify(newLocaleStorageSegment))
}

export const getLocalStorageValue = (key: keyof ISegmentKeys) => {
  const localeStorageSegment = getSegment()
  if (!key) return localeStorageSegment

  return localeStorageSegment?.[key]
}
