import * as React from 'react'
import Content from './Content'
import Footer from './Footer'
import Header from './Header'

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </>
  )
}

export default Layout
