import { useRouter } from 'next/router'
import { useQueryState } from 'nuqs'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import {
  ILang,
  ILanguage,
  IRouteQueryParams,
  IRouteQueryParamsWithDynamicParams,
} from '../store/data'

interface QueryParams {
  addQueryParameter: (param: string, value: string | null | undefined) => Promise<void>
  removeAllStateQueryParameters: () => Promise<void>
  removeQueryParameter: (param: keyof IRouteQueryParams) => Promise<void>
  removeQueryParametersArray: (params: Array<keyof IRouteQueryParams>) => Promise<void>
  routerQueryParams: IRouteQueryParamsWithDynamicParams
}

export const GUEST_PARAM = 'guest'
export const HOTEL_PARAM = 'hotel'
export const LANG_PARAM = 'lang'
export const ROOM_TYPE_PARAM = 'roomType'
export const VISIT_START_PARAM = 'visitStart'
export const VISIT_END_PARAM = 'visitEnd'
export const VISIT_TYPE_PARAM = 'visitType'
export const AFFIL_USER_NAME = 'affilUserName'
export const DESTINATION_PARAM = 'destination'

export const stateQueryParams = [
  GUEST_PARAM,
  HOTEL_PARAM,
  LANG_PARAM,
  ROOM_TYPE_PARAM,
  VISIT_START_PARAM,
  VISIT_END_PARAM,
  VISIT_TYPE_PARAM,
  AFFIL_USER_NAME,
  DESTINATION_PARAM,
]

export const useQueryParams = (): QueryParams => {
  const router = useRouter()
  const setDeletingQueryParameter = useBookingFormStore((state) => state.setDeletingQueryParameter)

  const [guest, setGuest] = useQueryState(GUEST_PARAM)
  const [hotel, setHotel] = useQueryState(HOTEL_PARAM)
  const [lang, setLang] = useQueryState(LANG_PARAM)
  const [roomType, setRoomType] = useQueryState(ROOM_TYPE_PARAM)
  const [visitStart, setVisitStart] = useQueryState(VISIT_START_PARAM)
  const [visitEnd, setVisitEnd] = useQueryState(VISIT_END_PARAM)
  const [visitType, setVisitType] = useQueryState(VISIT_TYPE_PARAM)
  const [affilUserName, setAffilUserName] = useQueryState(AFFIL_USER_NAME)
  const [destination, setDestination] = useQueryState(DESTINATION_PARAM)

  // Capture other dynamic parameters
  const dynamicParams = Object.fromEntries(
    Object.entries(router.query).filter(
      ([key]: [string, string | string[] | undefined]) => !stateQueryParams.includes(key)
    )
  )

  const setQueryParameterValueByName = async (param: string, value: string | null | undefined) => {
    setDeletingQueryParameter(true)

    if (stateQueryParams.includes(param)) {
      const valueToSet = value ?? null

      switch (param) {
        case GUEST_PARAM:
          await setGuest(valueToSet)
          break
        case HOTEL_PARAM:
          await setHotel(valueToSet)
          break
        case LANG_PARAM:
          await setLang(valueToSet)
          break
        case ROOM_TYPE_PARAM:
          await setRoomType(valueToSet)
          break
        case VISIT_START_PARAM:
          await setVisitStart(valueToSet)
          break
        case VISIT_END_PARAM:
          await setVisitEnd(valueToSet)
          break
        case VISIT_TYPE_PARAM:
          await setVisitType(valueToSet)
          break
        case AFFIL_USER_NAME:
          await setAffilUserName(valueToSet)
          break
        case DESTINATION_PARAM: {
          await setDestination(valueToSet)
          break
        }
        default:
      }
    } else {
      // Dynamic parameters are added or removed directly via router.push
      const updatedQuery = { ...router.query, ...dynamicParams, [param]: value }
      if (value === null) delete updatedQuery[param]

      await router.push(
        {
          pathname: router.pathname,
          query: updatedQuery,
        },
        undefined,
        { shallow: true }
      )
    }

    setDeletingQueryParameter(false)
  }

  const addQueryParameter = async (param: string, value: string | null | undefined) => {
    await setQueryParameterValueByName(param, value)
  }

  const removeQueryParameter = async (param: keyof IRouteQueryParams) => {
    await setQueryParameterValueByName(param, null)
  }

  const removeQueryParametersArray = async (params: Array<keyof IRouteQueryParams>) => {
    for await (const param of params) {
      await removeQueryParameter(param)
    }
  }

  const removeAllStateQueryParameters = async () => {
    await removeQueryParametersArray([
      GUEST_PARAM,
      HOTEL_PARAM,
      LANG_PARAM,
      ROOM_TYPE_PARAM,
      VISIT_START_PARAM,
      VISIT_END_PARAM,
      VISIT_TYPE_PARAM,
      AFFIL_USER_NAME,
      DESTINATION_PARAM,
    ])
  }

  const routerQueryParams = {
    affilUserName,
    destination,
    guest,
    hotel,
    lang: lang as keyof ILang | ILanguage | null,
    roomType,
    visitEnd,
    visitStart,
    visitType,
  }

  return {
    addQueryParameter,
    removeAllStateQueryParameters,
    removeQueryParameter,
    removeQueryParametersArray,
    routerQueryParams: {
      ...routerQueryParams,
      ...dynamicParams,
    },
  }
}
