import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  isDate,
  isValid,
  parse,
  startOfMonth,
  subMonths,
} from 'date-fns'
import { DATE_FORMAT_YYYY_MM_DD } from '../constants'
import { IDate } from '../store/data'

export const getDateForDatePickerComponent = (date: string | null) => {
  if (!date) return null

  return !isValid(new Date(date)) ? null : new Date(date)
}

// for Yup transform @internal
export const getDateOrNull = (_: any, originalValue: any) => {
  if (!originalValue) return null

  return isDate(originalValue) ? originalValue : new Date(originalValue)
}

export const parseDateString = (date: string) => parse(date, DATE_FORMAT_YYYY_MM_DD, new Date())

export const getDatesForCalendarDate = (calendarDate: string, dates: IDate[]) => {
  const input = new Date(calendarDate)
  const inputMonth = input.getMonth()
  const inputYear = input.getFullYear()

  return dates.filter(({ date: dateString }) => {
    const date = new Date(dateString)
    return date.getMonth() === inputMonth && date.getFullYear() === inputYear
  })
}

export const getDateKeysForCache = (
  dateFromCalendarDate: Date,
  dateMiddleCalendarDate: Date,
  dateToCalendarDate: Date
) => {
  const dateMiddleCalendarDateCacheKey = format(dateMiddleCalendarDate, DATE_FORMAT_YYYY_MM_DD)
  const dateToCalendarDateCacheKey = format(
    startOfMonth(dateToCalendarDate),
    DATE_FORMAT_YYYY_MM_DD
  )

  const dateFromCalendarDateRequest = format(dateFromCalendarDate, DATE_FORMAT_YYYY_MM_DD)
  // [SPA-144] added one day, due to backend bug with date_to parameter
  const dateToCalendarDateRequest = format(addDays(dateToCalendarDate, 1), DATE_FORMAT_YYYY_MM_DD)

  return {
    dateFromCalendarDateRequest,
    dateMiddleCalendarDateCacheKey,
    dateToCalendarDateCacheKey,
    dateToCalendarDateRequest,
  }
}

export const getRangeForCalendarDatesRequest = (
  calendarDate: Date,
  dateRangesCacheKeysSelector: string[],
  isCalendarInitial: boolean
) => {
  const start = startOfMonth(calendarDate)

  // if initial: load 1 month back, 1 month current and 1 next month
  // if not initial - we already have current and next month, so we will switch and load only 1 month in the future
  const dateFromCalendarDate = isCalendarInitial
    ? subMonths(start, 1)
    : startOfMonth(addMonths(calendarDate, 1))
  const dateMiddleCalendarDate = isCalendarInitial
    ? start
    : startOfMonth(addMonths(calendarDate, 1))
  const dateToCalendarDate = isCalendarInitial
    ? endOfMonth(addMonths(calendarDate, 1))
    : endOfMonth(addMonths(calendarDate, 1))

  const dateStartCacheKey = format(startOfMonth(dateToCalendarDate), DATE_FORMAT_YYYY_MM_DD)

  return {
    dateFromCalendarDate,
    dateMiddleCalendarDate,
    dateToCalendarDate,
    sendRequest: !dateRangesCacheKeysSelector.includes(dateStartCacheKey),
  }
}

export const getCalendarDates = (dates: IDate[]) => {
  let calendarDates: IDate[] = []

  calendarDates = dates.filter(
    (date) => ['AVAILABLE', 'ON_DEMAND'].includes(date.availability) && date.selectable
  )

  // If there is no available or on demand date, try to find not_available to roll over the calendar
  if (calendarDates.length === 0) {
    calendarDates = dates
  }

  // backend sometimes returns not sorted array for dates
  calendarDates.sort(
    (dateA, dateB) => new Date(dateA.date).getTime() - new Date(dateB.date).getTime()
  )

  return calendarDates
}
