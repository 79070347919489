import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '../Typography'
import styles from './Description.module.scss'

type DescriptionProps = {
  step: number
}

const Description: React.FunctionComponent<DescriptionProps> = ({ step }) => {
  const label = React.useMemo(() => {
    switch (step) {
      case 2:
        return (
          <FormattedMessage
            defaultMessage="Personal Details"
            description="Stepper description for Personal details"
          />
        )
      case 3:
        return (
          <FormattedMessage defaultMessage="Guests" description="Stepper description for Guests" />
        )
      case 4:
        return (
          <FormattedMessage
            defaultMessage="Wishes, and Loyalty programmes"
            description="Stepper description for Wishes and Loyalty programmes"
          />
        )
      default:
        return null
    }
  }, [step])

  return label ? (
    <Typography className={styles.root} variant="caption">
      {label}
    </Typography>
  ) : null
}

export default Description
