import { useRouter } from 'next/router'
import { ReactNode } from 'react'

interface RouterReadyProps {
  children: ReactNode
}

// We need to wait for the router to be ready (see more: https://github.com/vercel/next.js/discussions/11484)
export const RouterReady = ({ children }: RouterReadyProps) => {
  const router = useRouter()

  if (!router.isReady) {
    return null
  }

  return <>{children}</>
}
