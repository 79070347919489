import clsx from 'classnames'
import * as React from 'react'
import { useEffect } from 'react'
import { CompanyHeaderLinkWrapper } from '@components/CompanyHeaderLinkWrapper'
import { Logo } from '@components/Logo'
import { getHomepageLink } from '../helpers'
import { useIsDataFilled } from '../hooks/useIsDataFilled'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import Container from './Container'
import styles from './Header.module.scss'
import SelectLanguage from './SelectLanguage'
import Stepper from './Stepper'

const Header: React.FunctionComponent<React.PropsWithChildren> = () => {
  const isDataFilled = useIsDataFilled()
  const language = useBookingFormStore((state) => state.language)
  const step = useBookingFormStore((state) => state.stepper.step)

  useEffect(() => {
    if (step === 1) return

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDataFilled) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataFilled])

  return (
    <header
      className={clsx(styles.root, {
        [styles.rootShadow]: step > 1 && step < 5,
      })}
    >
      <Container>
        <div className={styles.wrapper}>
          <CompanyHeaderLinkWrapper link={getHomepageLink(language)}>
            <Logo />
          </CompanyHeaderLinkWrapper>
          {step < 6 && <Stepper />}
          <SelectLanguage />
        </div>
      </Container>
    </header>
  )
}

export default Header
