import * as React from 'react'
import { useIntl } from 'react-intl'
import { PathEnum, redirectTo } from '../helpers'
import { useAppLanguage } from '../hooks/useAppLanguage'
import { useConfirmLanguageSwitch } from '../hooks/useConfirmLanguageSwitch'
import { useIsDataFilled } from '../hooks/useIsDataFilled'
import { ILanguage, IRouteQueryParamsWithDynamicParams } from '../store/data'
import { useBookingFormStore } from './BookingFormStoreProvider'

export interface IAppContext {
  onChangeLanguage: (lang: ILanguage, routeQueryParams: IRouteQueryParamsWithDynamicParams) => void
}

export const AppContext = React.createContext<IAppContext>({
  onChangeLanguage: () => {},
})

export const useAppContext = () => React.useContext<IAppContext>(AppContext)

const AppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl()
  const isDataFilled = useIsDataFilled()
  const { onChangeAppLanguage } = useAppLanguage()
  const resetApplication = useBookingFormStore((state) => state.resetApplication)

  const confirmLanguageSwitch = useConfirmLanguageSwitch(isDataFilled)

  const onChangeLanguage = React.useCallback(
    (lang: ILanguage, routeQuery: IRouteQueryParamsWithDynamicParams) => {
      if (confirmLanguageSwitch()) {
        redirectTo(PathEnum.STEP_1, routeQuery, lang).then(() => {
          onChangeAppLanguage(lang)
          resetApplication()
        })
      }
    },
    [isDataFilled, formatMessage, resetApplication]
  )

  const value = React.useMemo<IAppContext>(() => {
    return {
      onChangeLanguage,
    }
  }, [onChangeLanguage])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppContextProvider
