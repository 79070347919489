import * as React from 'react'

type CloseIconProps = {
  size?: number
}

const CloseIcon: React.FunctionComponent<CloseIconProps> = ({ size = 24 }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.04883 7.05025L16.9483 16.9497"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M7.04883 16.9497L16.9483 7.05025"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default CloseIcon
