import * as React from 'react'
import { useMemo } from 'react'
import * as ReactDOM from 'react-dom'
import { FormattedMessage } from 'react-intl'
import Button from '@components/Button'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { INotification } from '../../store/data'
import Notification from './Notification'
import styles from './Notifications.module.scss'

const Notifications: React.FunctionComponent = () => {
  const notifications = useBookingFormStore((state) => state.notifications)
  const removeNotification = useBookingFormStore((state) => state.removeNotification)

  const notificationsArray: INotification[] = useMemo(() => {
    if (notifications.length === 0) {
      return []
    }

    const networkErrorNotification = notifications.find(
      (notification) => notification.isNetworkError
    )

    if (!networkErrorNotification) {
      return notifications
    }

    return [
      {
        ...networkErrorNotification,
        message: (
          <FormattedMessage
            defaultMessage="It looks like we're having a problem with the connection. Please check your internet connection and try to reload the page."
            description="Network error notification description"
          />
        ),
      },
    ]
  }, [notifications])

  if (notificationsArray.length === 0) {
    return null
  }

  return ReactDOM.createPortal(
    <div className={styles.root}>
      {notificationsArray.map((notification) => (
        <Notification
          actionButton={
            notification.isNetworkError ? (
              <Button
                className={styles.rootButtonRefresh}
                onClick={() => window.location.reload()}
                variant="outlined"
              >
                <FormattedMessage
                  defaultMessage="Reload the page "
                  description="Reload the page description button"
                />
              </Button>
            ) : undefined
          }
          closable={notification.closable}
          key={notification.timestamp}
          message={notification.message}
          onClose={() => removeNotification(notification)}
        />
      ))}
    </div>,
    document.body
  )
}

export default Notifications
