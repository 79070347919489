import '@styles/index.scss'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import NProgress from 'nprogress'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import * as React from 'react'
import Layout from '@components/Layout'
import Notifications from '@components/Notifications'
import { RouterReady } from '@components/RouterReady'
import { getLayoutConfig } from '../config'
import AppContextProvider from '../providers/AppContext'
import { BookingFormStoreProvider } from '../providers/BookingFormStoreProvider'
import IntlProvider from '../providers/IntlProvider'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const AppComponentWithLayoutAndProviders = ({ Component, pageProps }: AppProps) => {
  const config = getLayoutConfig()

  return (
    <IntlProvider>
      <AppContextProvider>
        <Head>
          <title>{config?.title ?? 'Spa Booking'}</title>
          <meta charSet="utf-8" />
          <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport" />
          <link href="/favicon.svg" rel="shortcut icon" />
        </Head>
        <Layout>
          <RouterReady>
            <Component {...pageProps} />
          </RouterReady>
        </Layout>
        <Notifications />
      </AppContextProvider>
    </IntlProvider>
  )
}

const MyApp: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => {
  return (
    <NuqsAdapter>
      <BookingFormStoreProvider>
        <AppComponentWithLayoutAndProviders Component={Component} {...pageProps} />
      </BookingFormStoreProvider>
    </NuqsAdapter>
  )
}

export default MyApp
