import clsx from 'classnames'
import * as React from 'react'
import { ReactNode } from 'react'
import styles from './BasicButton.module.scss'
import Spinner from './Spinner'

export interface BasicButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children?: ReactNode
  className?: string
  clearBasicStyle?: boolean
  disabled?: boolean
  loading?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: 'submit' | 'reset' | 'button'
}

const BasicButton = ({
  children,
  className,
  clearBasicStyle = false,
  disabled = false,
  loading = false,
  onClick,
  type = 'button',
  ...rest
}: BasicButtonProps) => {
  const buttonContent = loading ? (
    <span className="flex items-center relative">
      <Spinner className="absolute text-center right-0 left-0 top-0 bottom-0 m-auto" />
      <span className={clsx('flex items-center truncate', { 'opacity-50': loading })}>
        {children}
      </span>
    </span>
  ) : (
    children
  )

  return (
    <button
      {...rest}
      className={clsx(
        styles.root,
        {
          [styles.rootPadding]: !clearBasicStyle,
        },
        className
      )}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      {buttonContent}
    </button>
  )
}

export default BasicButton
