import * as React from 'react'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import Description from './Description'
import styles from './Stepper.module.scss'
import StepperItem from './StepperItem'

const Stepper: React.FunctionComponent = () => {
  const demand = useBookingFormStore((state) => state.demand)
  const step = useBookingFormStore((state) => state.stepper.step)
  const totalStep = useBookingFormStore((state) => state.stepper.totalStep)

  let orderTypeTotalSteps = totalStep
  let orderTypeStep = step

  if (demand) {
    orderTypeTotalSteps = totalStep - 1
    if (step > 2) {
      orderTypeStep = step - 1
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.rootWrapper}>
        {Array.from(Array(orderTypeTotalSteps).keys())
          .map((item) => item + 1)
          .map((value) => (
            <StepperItem
              key={value}
              label={value}
              separator={value < orderTypeTotalSteps}
              step={orderTypeStep}
            />
          ))}
      </div>
      <Description step={step} />
    </div>
  )
}

export default Stepper
