import * as React from 'react'

type CheckIconProps = {
  size?: number
}

const CheckIcon: React.FunctionComponent<CheckIconProps> = ({ size = 13 }) => (
  <svg
    fill="none"
    height="13"
    viewBox="0 0 18 13"
    width={Math.round(size / (18 / 13))}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 1L6 12L1 7"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default CheckIcon
