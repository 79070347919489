import * as React from 'react'
import {
  getCustomAppInstanceLanguage,
  getInstanceLanguageDomain,
  getSupportedLayoutLocales,
} from '../helpers'
import { useConfirmLanguageSwitch } from '../hooks/useConfirmLanguageSwitch'
import { useIsDataFilled } from '../hooks/useIsDataFilled'
import { useAppContext } from '../providers/AppContext'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { ILanguage } from '../store/data'
import styles from './SelectLanguage.module.scss'

const localeLabels = {
  cs: 'CZ',
  de: 'DE',
  en: 'EN',
  ru: 'RU',
}

const SelectLanguage: React.FunctionComponent = () => {
  const { onChangeLanguage } = useAppContext()
  const language = useBookingFormStore((state) => state.language)
  const isDataFilled = useIsDataFilled()
  const id = React.useId()

  const supportedLocales = getSupportedLayoutLocales()
  const confirmLanguageSwitch = useConfirmLanguageSwitch(isDataFilled)

  const handleChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const languageToChange = target.value as ILanguage
    const isAppWithCustomInstanceLanguage = !!getCustomAppInstanceLanguage()

    if (!isAppWithCustomInstanceLanguage) {
      onChangeLanguage(languageToChange, {})
      return
    }

    if (confirmLanguageSwitch()) {
      const instanceLanguageDomain = getInstanceLanguageDomain(languageToChange)
      window.location.href = instanceLanguageDomain
    }
  }

  return (
    <div className={styles.root}>
      <select id={id} onChange={handleChange} value={language}>
        {supportedLocales.map((locale) => (
          <option key={locale} value={locale}>
            {localeLabels[locale]}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectLanguage
