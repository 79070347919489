import { format } from 'date-fns'
import { DATE_FORMAT_YYYY_MM_DD } from '../constants'
import { isGuestDefined } from '../helpers'
import { GuestFormData, ServiceFormData } from '../store/data'

export const prepareGuestDataForBookingRequest = (
  guestsData: GuestFormData[],
  servicesFormData: ServiceFormData
) => {
  return guestsData.map((guest, index) => {
    // A guest is optional for a demand, but in this case, we must provide all mandatory data as placeholders.
    const guestDefined = isGuestDefined(guest)

    return {
      ...(!guestDefined
        ? {
            birth_date: format(
              new Date(Math.floor(new Date().getTime() / 1000)),
              DATE_FORMAT_YYYY_MM_DD
            ),
            first_name: 'Guest',
            gender: 'male',
            last_name: `${index + 1}`,
          }
        : {
            birth_date: format(new Date(guest.birth_date!), DATE_FORMAT_YYYY_MM_DD),
            first_name: guest.first_name,
            gender: guest.gender,
            last_name: guest.last_name,
          }),
      loyalty_program_code: servicesFormData.loyaltyPrograms?.[index]?.code || '',
      loyalty_program_id: servicesFormData.loyaltyPrograms?.[index]?.id || '',
    }
  })
}
