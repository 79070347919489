import clsx from 'classnames'
import * as React from 'react'
import styles from './Container.module.scss'

type ContainerProps = {
  className?: string
  component?: React.ElementType
  padding?: boolean
  variant?: 'normal' | 'small'
}

const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  className = undefined,
  component = 'div',
  padding = true,
  variant = 'normal',
}) => {
  return React.createElement(
    component,
    {
      className: clsx(
        styles.root,
        {
          [styles.rootNormal]: variant === 'normal',
          [styles.rootPadding]: padding,
          [styles.rootSmall]: variant === 'small',
        },
        className
      ),
    },
    children
  )
}

export default Container
