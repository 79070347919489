import clsx from 'classnames'
import * as React from 'react'
import styles from './Typography.module.scss'

type TypographyProps = {
  className?: string
  component?: React.ElementType
  variant?: 'body' | 'caption' | 'category' | 'headline' | 'small' | 'subcategory' | 'extra-small'
}

const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = ({
  children,
  className = undefined,
  component = 'p',
  variant = 'body',
  ...rest
}) => {
  return React.createElement(
    component,
    {
      ...rest,
      className: clsx(
        styles.root,
        {
          [styles.rootBody]: variant === 'body',
          [styles.rootCaption]: variant === 'caption',
          [styles.rootCategory]: variant === 'category',
          [styles.rootHeadline]: variant === 'headline',
          [styles.rootSmall]: variant === 'small',
          [styles.rootExtraSmall]: variant === 'extra-small',
          [styles.rootSubcategory]: variant === 'subcategory',
        },
        className
      ),
    },
    children
  )
}

export default Typography
